<template>
  <section class="content">
    <table class="table table-hover" ref="tbl_pengajuan_budget" style="width:100%">
      <thead>
        <tr>
          <th>Nomor PO</th>
          <th>Tanggal</th>
          <th>Kategori</th>
          <th>Oleh</th>
          <th>Cabang</th>
          <th>Nominal</th>
          <th>Keterangan</th>
          <th>Dokumen</th>
          <th>Approval</th>
          <th>Approval Pengajuan</th>
          <th>Approval LPJ</th>
          <th>LPJ</th>
          <th>Status Check</th>
          <th>Paid</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:600px">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal Pemakaian</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <input
                    id="keterangan"
                    class="form-control"
                    v-model="form.keterangan"
                    type="text"
                    required="required"
                    name="keterangan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nilai Total</label>
                  <currency-input id="nilai_total" class="form-control" currency="IDR" v-model="form.nilai_total" :precision="0" name="nilai_total" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Unit</label> <br />
                  <v-select :options="optUnit" v-model="form.unit_id" :reduce="opt => opt.id" />
                </div>
              </div>
              <div class="col-12" style="width:100%">
                <table class="table table-hover" ref="tablebarang" style="width:100%" id="tablebarang">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Note</th>
                            <th>Harga</th>
                            <th>QTY</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4" style="text-align: right;">Total</td>
                            <td class="total-bgt"></td>
                        </tr>
                    </tfoot>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialogInventaris"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:600px">
        <div class="modal-content">
          <form role="form" @submit="submitForm" id="formInventaris">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal Pemakaian</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <input
                    id="keterangan"
                    class="form-control"
                    v-model="form.keterangan"
                    type="text"
                    name="keterangan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nilai Total</label>
                  <currency-input id="nilai_total" class="form-control" currency="IDR" v-model="form.nilai_total" :precision="0" name="nilai_total" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
              </div>
              <div class="row" style="width:100%">
                <table class="table table-hover" ref="tablebaranginv" style="width:100%" id="tablebaranginv">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Barang</th>
                            <th>Harga</th>
                            <th>QTY</th>
                            <th>Note</th>
                            <th>Note</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5" style="text-align: right;">Total</td>
                            <td colspan="2" class="total-inv"></td>
                        </tr>
                    </tfoot>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialogMaintenance"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:600px">
        <div class="modal-content">
          <form role="form" @submit="submitForm" id="formMT">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <textarea class="form-control" v-model="form.keterangan"></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nilai Total</label>
                  <currency-input id="nilai_total" class="form-control" currency="IDR" v-model="form.nilai_total" :precision="0" name="proposal_anggaran" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialogUangMakan"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:600px">
        <div class="modal-content">
          <form role="form" @submit="submitForm" id="formPO">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <input
                    id="keterangan"
                    class="form-control"
                    v-model="form.keterangan"
                    type="text"
                    name="keterangan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nilai Total</label>
                  <currency-input id="nilai_total" class="form-control" currency="IDR" v-model="form.nilai_total" :precision="0" name="nilai_total" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialogPR"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document" style="max-width:624px">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Staff</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal Proposal</label>
                  <datepicker v-model="form.date" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Keterangan</label>
                  <input
                    id="keterangan"
                    class="form-control"
                    v-model="form.keterangan"
                    type="text"
                    required="required"
                    name="keterangan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Anggaran</label>
                  <currency-input id="proposal_anggaran" class="form-control" currency="IDR" v-model="form.nilai_total" :precision="0" name="proposal_anggaran" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label> <br />
                  <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Tanggal Kegiatan</label>
                    <datepicker
                      v-model="form.date_action"
                    />
                </div>
              </div>
              <div class="col-12" style="width:100%">
                <table class="table table-hover" ref="tablepr" style="width:100%" id="tablepr">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Note</th>
                            <th>Harga</th>
                            <th>QTY</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4" style="text-align: right;">Total</td>
                            <td class="total_anggaran"></td>
                        </tr>
                    </tfoot>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from "@/components/Datepicker";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { CurrencyInput } from "vue-currency-input";

let idr = Intl.NumberFormat("en-ID", {
    style: "currency",
    currency: "IDR",
    useGrouping: true,
    maximumSignificantDigits: 3,
});

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Budgeting",
      tblbgt: '',
      tblinf: '',
      tblpr: '',
      userInfo: '',
      optStaff: [],
      optBranch: [],
      optStaff: [],
      optUnit: [],
      counterHarga: 0,
      counterQty: 0,
      counterTotal: 0,
      form: {
        staff_id: "",
        date: "",
        branch_id: "",
        keterangan: "",
        nilai_total: "",
        items: [],
        type: 1
      },

    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    console.log("load initial data", this.$route);

    authFetch('/accounting/pengajuan/staff')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optStaff = js.data;
      })

    authFetch('/accounting/pengajuan/branch')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optBranch = js.data;
      })
    authFetch('/administrasi/pengajuan_budget/staff')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optStaff = js.data;
      })
    authFetch('/administrasi/pengajuan_budget/unit')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optUnit = js.data;
      })
  },
  components: {
    vSelect,
    datepicker,
    datePicker,
    CurrencyInput,
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-info') {
        this.approve(e.srcElement.getAttribute('data-id'))
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-danger') {
        this.reject(e.srcElement.getAttribute('data-id'))
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-info lpj-approve') {
        this.approveLPJ(e.srcElement.getAttribute('data-id'))
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-danger lpj-reject') {
        this.rejectLPJ(e.srcElement.getAttribute('data-id'))
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
        this.download(e.srcElement.getAttribute('data-href'), e.srcElement.getAttribute('data-name'));
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-info preview') {
        this.preview(e.srcElement.getAttribute('data-href'));
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-sm paid btn-primary') {
        this.paid(e.srcElement.getAttribute('data-id'));
      }

      if (e.target.closest("button")) {
        var btnEl = $(e.target).closest("button").get(0);
        if (btnEl.dataset.action == "confirm") {
          var id = btnEl.dataset.id;
          Swal.fire({
          title: "Konfirmasi?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          }).then((result) => {
          if (result.isConfirmed) {
          authFetch('/accounting/pengajuan/check/'+id, {
              method: "PUT",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
              body: 'id='+id,
          })  .then((res) => {
              return res.json();
              }).then((js) => {
              if(js.success)
              {
                Swal.fire("Proses Berhasil", ``, "success");
                this.table.api().ajax.reload();
              } else {
                Swal.fire("Proses Gagal", ``, "error");
              }
              });
          }
          });
        }
        return false;
      }
    },
    preview: function(data) {
        window.open(window.$apiUrl+data, "_blank");
    },
    download: function(code, name) {
        this.loadingDownload = true;
        authFetch("/accounting/pengajuan/download/"+code, {
            method: 'GET',
            headers: {
            'Content-Type': null,
            }
        })
        .then(response => {
          console.log(response)
          // const filename =  response.headers.get('Content-Disposition');//.split('filename=')[1];
          response.blob().then(blob => {
              this.loadingDownload = false;
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = name;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              a.remove();  //afterwards we remove the element again         
          });
        })
    },
    approve: function(id) {
        authFetch('/accounting/pengajuan/approve', {
            method: 'POST',
            body: 'status=1&id='+id
        })
        .then(res => {
            if (res.status === 201) {

            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {

            this.errors = [];
            if (!js.success) {
                    for (var key in js) {
                        if (js.hasOwnProperty(key)) {
                            this.errors.push(js[key])
                        }
                    }

                    return;
                }
            this.table.api().ajax.reload();
            Swal.fire({
                icon: 'success',
                title: 'Approved',
                text: 'Pengajuan telah di approve.'
            })
        });
    },
    approveLPJ: function(id) {
        authFetch('/accounting/pengajuan/approve_lpj', {
            method: 'POST',
            body: 'status=1&id='+id
        })
        .then(res => {
            if (res.status === 201) {

            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {

            this.errors = [];
            if (!js.success) {
                    for (var key in js) {
                        if (js.hasOwnProperty(key)) {
                            this.errors.push(js[key])
                        }
                    }

                    return;
                }
            this.table.api().ajax.reload();
            Swal.fire({
                icon: 'success',
                title: 'Approved',
                text: 'LPJ telah di approve.'
            })
        });
    },
    reject: function(id) {
        authFetch('/accounting/pengajuan/approve', {
            method: 'POST',
            body: 'status=100&id='+id
        })
        .then(res => {
            if (res.status === 201) {

            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {

            this.errors = [];
            if (!js.success) {
                    for (var key in js) {
                        if (js.hasOwnProperty(key)) {
                            this.errors.push(js[key])
                        }
                    }

                    return;
                }
            this.table.api().ajax.reload();
            Swal.fire({
                icon: 'success',
                title: 'Reject',
                text: 'Pengajuan telah di rejected.'
            })
        });
    },
    rejectLPJ: function(id) {
        authFetch('/accounting/pengajuan/approve_lpj', {
            method: 'POST',
            body: 'status=100&id='+id
        })
        .then(res => {
            if (res.status === 201) {

            } else if (res.status === 400) {}
            return res.json();
        })
        .then(js => {

            this.errors = [];
            if (!js.success) {
                    for (var key in js) {
                        if (js.hasOwnProperty(key)) {
                            this.errors.push(js[key])
                        }
                    }

                    return;
                }
            this.table.api().ajax.reload();
            Swal.fire({
                icon: 'success',
                title: 'Reject',
                text: 'LPJ telah di rejected.'
            })
        });
    },
    loadBarang: async function() {
        let vm = this;
        const res = await authFetch('/accounting/pengajuan/barang');
        vm.optBarang = await res.json();
    },
    addItem: function() {
        this.counterHarga = 0;
        this.counterQty = 0;
        this.counterTotal = 0;
        var nRow = this.tblbgt.fnAddRow('<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>');
        $('td .btn.edit', nRow).click();
    },
    submitForm: function (ev) {
      
    },
    paid: function(id) {
      Swal.fire({
          title: "Cairkan?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          }).then((result) => {
          if (result.isConfirmed) {
            authFetch('/accounting/pengajuan/paid', {
                method: 'POST',
                body: 'is_paid=t&id='+id
            })
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {

                this.errors = [];
                if (!js.success) {
                        for (var key in js) {
                            if (js.hasOwnProperty(key)) {
                                this.errors.push(js[key])
                            }
                        }

                        return;
                    }
                this.table.api().ajax.reload();
                Swal.fire({
                    icon: 'success',
                    title: 'Approved',
                    text: 'Pengajuan telah di cairkan.'
                })
            });
          }
      });
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    let tblbgt;
    let tblinv;
    let tblpr;

    var isSuperuser = self.userInfo.role_id == 1;
    var isAccounting = self.userInfo.role_id == 11;
    var isManagement = self.userInfo.role_id == 17;
    this.table = createTable(e.tbl_pengajuan_budget, {
      title: "",
      roles: ['update'],//this.$route.params.roles,
      ajax: "/accounting/pengajuan",
      scrollX: true,
      columns: [
        { data: "code" },
        { data: "date" },
        { data: "type" },
        { data: "staff" },
        { data: "branch" },
        { data: "nilai_total" },
        { data: "keterangan" },
        { data: "filecode_doc"},
        { data: "status" },
        { data: "status" },
        { data: "status" },
        { data: "filecode_lpj"},
        { data: "status_check",
        sortable: false,
        render: function (data, type, row, meta) {
          if (isAccounting || isSuperuser) {
            if (data < 1) {
              return `<div class="btn-group">
            <button type="button" class="btn btn-sm btn-primary" data-action="confirm" data-id="` + row.id +`"><i class="fas fa-check"></i> Konfirmasi</button>
            </div>`;
            } else {
              return '<span style="font-size:100%" class="badge badge-success">SUDAH DICHECK</span>';
            }
          }

          if (isManagement) {
            if (data < 1) {
              return '<span style="font-size:100%" class="badge badge-info">BELUM DICHECK</span>';
            } else {
              return '<span style="font-size:100%" class="badge badge-success">SUDAH DICHECK</span>';
            }
          }
        }},
        { data: "is_paid",
        sortable: false,
        render: function (data, type, row, meta) {
          if (isAccounting || isSuperuser) {
            if (data && data == 'f') {
              return `<div class="btn-group">
            <button type="button" class="btn btn-sm paid btn-primary" data-action="paid" data-id="` + row.id +`"><i class="fas fa-check"></i> CAIRKAN</button>
            </div>`;
            } else {
              return '<span style="font-size:100%" class="badge badge-success">SUDAH DI CAIRKAN</span>';
            }
          }

          if (isManagement) {
            if (data == 't') {
              return '<span style="font-size:100%" class="badge badge-info">SUDAH DI CAIRKAN</span>';
            } else {
              return '<span style="font-size:100%" class="badge badge-success">BELUM DI CAIRKAN</span>';
            }
          }
        }},
      ],
      filterBy: [0, 1, 3, 4, 6],
      frame: true,
      rowCallback: function (row, data) {
        let idr = Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: "IDR",
            useGrouping: true,
            maximumSignificantDigits: 3,
        });
        $("td:eq(5)", row).html(idr.format(data.nilai_total));

        if (data.status == 100) {
          $("td:eq(8)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-danger">Rejected</span>');
        } else if (data.status == 1) {
          $("td:eq(8)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-success">Approved</span>');
        } else {
          $("td:eq(8)", row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-info">Proses</span>');
        }

        if (!data.type) {
          $("td:eq(2)", row).html('TIDAK TERKATEGORI');
          $(row).find('td:eq(2)').css('background', '#F4D19B');
        }
        if (data.type == 1) {
          $("td:eq(2)", row).html('BUDGET');
          $(row).find('td:eq(2)').css('background', '#C1FFD7');
        }
        if (data.type == 2) {
          $("td:eq(2)", row).html('INVENTARIS');
          $(row).find('td:eq(2)').css('background', '#B5DEFF');
        }
        if (data.type == 3) {
          $("td:eq(2)", row).html('PO');
          $(row).find('td:eq(2)').css('background', '#CAB8FF');
        }
        if (data.type == 4) {
          $("td:eq(2)", row).html('UANG MAKAN');
          $(row).find('td:eq(2)').css('background', '#ABA6C5');
        }
        if (data.type == 5) {
          $("td:eq(2)", row).html('MAINTENANCE');
          $(row).find('td:eq(2)').css('background', '#FCFFA6');
        }
        if (data.type == 6) {
          $("td:eq(2)", row).html('PENGAJUAN KEGIATAN PR');
          $(row).find('td:eq(2)').css('background', '#DFEFA0');
        }

        if (data.status == '1' || data.status == '100') {
          $('td:eq(9)', row).html('Approval Selesai');
        } else {
          $('td:eq(9)', row).html('<div class="btn-group"><a type="button" class="btn btn-info" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.id+'" style="color:white">Approve</a><a type="button" class="btn btn-danger" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.id+'" style="color:white">Reject</a></div>');
        }
        if (data.filecode_lpj) {
          if (data.approval_lpj == '1' || data.approval_lpj == '100') {
            if (data.approval_lpj == '1') {
              $('td:eq(10)', row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-success">Approved</span>');
            } else {
              $('td:eq(10)', row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-danger">Rejected</span>');
            }
          } else {
            $('td:eq(10)', row).html('<div class="btn-group"><a type="button" class="btn btn-info lpj-approve" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.id+'" style="color:white">Approve</a><a type="button" class="btn btn-danger lpj-reject" data-toggle="modal" data-target="#modal-riwayat" data-id="'+data.id+'" style="color:white">Reject</a></div>');
          }
        } else {
          $('td:eq(10)', row).html('<span style="padding:0.5em;font-size:94%" class="badge badge-info">Belum ada LPJ</span>');
        }
        if(data.filecode_lpj)$('td:eq(11)', row).html('<a type="button" class="btn btn-info preview" data-href="/file/view/'+data.filecode_lpj+'" data-name="'+data.file_actual+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="btn btn-primary" data-href="'+data.filecode_lpj+'" data-name="'+data.file_actual+'"><i class="fas fa-download" style="pointer-events:none"></i></a>');
        if(data.filecode_doc)$('td:eq(7)', row).html('<a type="button" class="btn btn-info preview" data-href="/file/view/'+data.filecode_doc+'" data-name="'+data.file_actual_doc+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="btn btn-primary" data-href="'+data.filecode_doc+'" data-name="'+data.file_actual_doc+'"><i class="fas fa-download" style="pointer-events:none"></i></a>');
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.form.nilai_total = parseFloat(self.form.nilai_total)
          self.errors = [];

          if (self.form.type == '1') {
              self.formTitle = "Detail Budgeting";
              this.tblbgt.api().ajax.url(window.$apiUrl+"/administrasi/pengajuan_budget/item/"+self.form.id).load();
              $(e.formDialog).modal("show");
          } else if (self.form.type == '2') {
              self.formTitle = "Detail Inventaris";
              this.tblinf.api().ajax.url(window.$apiUrl+"/administrasi/pengajuan_budget/item/"+self.form.id).load();
              $(e.formDialogInventaris).modal("show");
          } else if (self.form.type == '3') {
              // url = 'pengajuan-po';
          } else if (self.form.type == '4') {
              self.formTitle = "Detail Uang Makan";
              $(e.formDialogUangMakan).modal("show");
          } else if (self.form.type == '5') {
              self.formTitle = "Detail Maintenance";
              $(e.formDialogMaintenance).modal("show");
          } else if (self.form.type == '6') {
              self.formTitle = "Detail Pengajuan Kegiatan PR";
              $(e.formDialogPR).modal("show");
              this.tblpr.api().ajax.url(window.$apiUrl+"/administrasi/pengajuan_budget/item/"+self.form.id).load();
          } else {
              // url = 'pengajuan-budget';
          }
          this.counterHarga = 0;
          this.counterQty = 0;
          this.counterTotal = 0;
        } else if (evt.role == "delete" && evt.data) {
          
        }
      },
    });

    tblbgt = this.tblbgt = createTable(e.tablebarang, {
        "title": "Rincian Barang",
        serverSide: false,
        filter: false,
        paging: false,
        scrollX: true,
        "ajax": "/administrasi/pengajuan_budget/item",
        "columns": [
            { "data": "ids", visible: false},
            { "data": "note"},
            { "data": "harga"},
            { "data": "qty"},
            { "data": "total" },
        ],
        rowCallback: function (row, data) {
            $("td:eq(1)", row).html(idr.format(data.harga));
            $("td:eq(3)", row).html(idr.format(data.total));
        },
        "footerCallback": function(row, data, start, end, display) {
            var api = this.api();

            // Remove the formatting to get integer data for summation
            var intVal = function(i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                    i : 0;
            };

            // Total over all pages
            var total = 0;
            data.map(function(val, i) {
                total = total + parseFloat(val.total);
            })
            $('.total-bgt').html(idr.format(total));
        }
    })

    tblinv = this.tblinf = createTable(e.tablebaranginv, {
        "title": "Rincian Barang",
        serverSide: false,
        filter: false,
        paging: false,
        scrollX: true,
        "ajax": "/administrasi/pengajuan_budget/item",
        "columns": [
            { "data": "ids", visible: false},
            { "data": "barang"},
            { "data": "harga"},
            { "data": "qty"},
            { "data": "note" },
            { "data": "barang_id", visible: false },
            { "data": "total" }
        ],
        rowCallback: function (row, data) {
            $("td:eq(1)", row).html(idr.format(data.harga));
            $("td:eq(4)", row).html(idr.format(data.harga*data.qty));
        },
        "footerCallback": function(row, data, start, end, display) {
            var api = this.api();

            // Remove the formatting to get integer data for summation
            var intVal = function(i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                    i : 0;
            };

            // Total over all pages
            var total = 0;
            data.map(function(val, i) {
                total = total + parseFloat(val.total);
            })
            $('.total-inv').html(idr.format(total));
        }
    })

    tblpr = this.tblpr = createTable(e.tablepr, {
        "title": "Rincian Item",
        serverSide: false,
        filter: false,
        paging: false,
        scrollX: true,
        "ajax": "/administrasi/pengajuan_budget/item",
        "columns": [
            { "data": "ids", visible: false},
            { "data": "note" },
            { "data": "harga"},
            { "data": "qty"},
            { "data": "total" },
        ],
        rowCallback: function (row, data) {
            $("td:eq(1)", row).html(idr.format(data.harga));
            $("td:eq(3)", row).html(idr.format(data.total));
        },
        "footerCallback": function(row, data, start, end, display) {
            var api = this.api();

            // Remove the formatting to get integer data for summation
            var intVal = function(i) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '') * 1 :
                    typeof i === 'number' ?
                    i : 0;
            };

            // Total over all pages
            var total = 0;
            data.map(function(val, i) {
                total = total + parseFloat(val.total);
            })
            $('.total_anggaran').html(idr.format(total));
        }
    })
  },
};
</script>
<style scoped>
  .card {
    width: 100%;
  }

  .modal-body.row.card.box-table {
    width: 100%;
  }

  select .dt .editor {
    width: 100%;
  }
  .icon-edit {
    color: white;
  }
  icon-remove {
    color: white; 
  }

  .btn {
      color: white;
  }
  a:not([href]):not([tabindex]) {
      color: white;
  }
</style